import axios from 'axios';
import {merge} from 'lodash';
import {state} from 'src/store/modules/auth.js'
import store from "../../store";
import router from "../../routes/routes";

export default class BackendService {

    constructor() {
        this.http = axios;
        this.apiUrl = 'https://api-dev.nextohotel.com/';
        //this.apiUrl = 'https://api-mongo44-xmnqf.ondigitalocean.app/';
        //this.apiUrl = 'https://motor-api-bokea.ondigitalocean.app/';
        //this.apiUrl = 'http://localhost:3000/';
        //this.apiUrl = 'https://api-prod-motor.nextohotel.com/';
    }

    async httpCall(url, data, statusToken = true) {
        let defaults = {
            url: this.apiUrl + url,
            method: 'GET',
        };
        if(statusToken){
            defaults.headers = {
                'token': state.token
                //'token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InN0YXR1cyI6dHJ1ZSwiaXNfYWRtaW4iOmZhbHNlLCJfaWQiOiI1ZThmODYxZWI5YzVkZjAwMTdlOGM0MjIiLCJmaXJzdF9uYW1lIjoidXNlciIsImxhc3RfbmFtZSI6InRlc3QiLCJlbWFpbCI6InVzZXJAYm9vc3RseS5jb20ubXgiLCJsYWRhIjoiKzUyIiwicGhvbmUiOiIxMjMxMjMxMjMwIiwicGVybWlzc2lvbnMiOlt7InNlY3Rpb25zIjpbMiwxLDEsMSwxLDFdLCJfaWQiOiI1ZThmODYxZmI5YzVkZjAwMTdlOGM0MjMiLCJwcm9wZXJ0eSI6IjVkZjJhN2U5ZjdkYTYwMDAxNzc5MTc1ZSIsImNyZWF0ZWRBdCI6IjIwMjAtMDQtMDlUMjA6MzE6MjcuMjU1WiIsInVwZGF0ZWRBdCI6IjIwMjAtMDUtMjNUMTc6MDM6MzIuODM1WiJ9XSwiY3JlYXRlZEF0IjoiMjAyMC0wNC0wOVQyMDozMToyNi45MzFaIiwidXBkYXRlZEF0IjoiMjAyMC0wNS0yM1QxNzowMzozMi44MzVaIiwiX192Ijo5fSwiaWF0IjoxNjAwMTA4MjkyLCJleHAiOjE2MDI3MDAyOTJ9.m_NDm8pDKgP8CmGbcJ_hOTHu9kpCEHfb3Ul-MnqIzNM'
            };
        }

        let mergeData = merge(defaults, data);
        let response = await this.http(mergeData).catch(error=>{
            //console.log('error', error.response);
            if(error.response.status === 401 && (error.response.data.err.name === 'JsonWebTokenError' ||  error.response.data.err.name === 'TokenExpiredError')){
                store.commit("AUTH_LOGOUT");
                router.push("/login");
            }
            return new Promise((resolve, reject) => {
                    reject(error);
            })
        });
        return new Promise((resolve, reject) => {
            if (response.status >= 200 && response.status < 300) {
                //console.log('Response with code: ' + response.status + '\nContent: ' + response.statusText);
                resolve(response);
            } else {
                reject(response);
            }
        })
    }

    httpAll(promises) {

        return this.http.all(promises);

    }

}
