<template>
  <nav class="navbar navbar-expand-lg">
    <div class="img-logo" v-show="selected_property!==null">
      <img :src="selected_property.logo" alt="avatar" v-show="selected_property.logo!==''" />
    </div>
    <div class="container-fluid">
      <div class="collapse navbar-collapse justify-content-end">
          <div class="contain-nav" style="width: 100%; display: flex; justify-content: flex-end;">


            <div style="display: inline-block;" v-if="properties.length>1">
              <i class="fa fa-building" aria-hidden="true"></i>
              <el-select v-model="active" placeholder="Select"
                         @change="handleProperty(active)">
                <el-option
                        v-for="(hotel,index) in properties"
                        :key="hotel._id"
                        :label="hotel.name"
                        :value="hotel._id">
                  <span style="margin-right: 10px; color: #8492a6; font-size: 17px; line-height: 32px;"  class="fa fa-building"></span>
                  <span>{{ hotel.name }}</span>
                </el-option>
              </el-select>
            </div>






            <!--<drop-down v-show="properties.length>1" class="property_container" position="right">
              <template slot="title">
                <label class="lbl-navbar">{{selected_property.name}}</label>
              </template>
              <i class="fa fa-building" aria-hidden="true"></i>
              <a
                      class="dropdown-item"
                      :class="(selected_property._id===selected_property._id)?'selected':''"
                      href="#"
                      v-for="(hotel,index) in properties"

                      :key="hotel._id"
              >
                <i class="fa fa-building" aria-hidden="true"></i>
                {{hotel.name}}
              </a>
            </drop-down>-->
            <div style="display: inline-block;" v-if="properties.length===1 && selected_property!==null">
              <label
                      class="lbl-navbar" style="margin-top: 7px"
                      :class="properties.length===1?'mt-space':''"

              ><i class="fa fa-building" aria-hidden="true" style="margin-right: 7px"></i>{{selected_property.name}}</label>
            </div>

            <div style="display: inline-block;" >
              <i class="fa fa-user"></i>
              <el-select placeholder="Select" :value="active_user"
                         @change="menuUser">

                <!--<el-option value="name_user">
                  <span style="margin-right: 10px; color: #8492a6; font-size: 17px; line-height: 32px;"  class="fa fa-user"></span>
                  <span>{{currentUser.email}}</span>
                </el-option>-->
                <el-option value="edit">
                  <span style="margin-right: 10px; color: #8492a6; font-size: 17px; line-height: 32px;"  class="fa fa-cog"></span>
                  <span>Editar cuenta</span>
                </el-option>
                <el-option value="logout" class="text-danger">
                  <span style="margin-right: 10px;  font-size: 17px; line-height: 32px;"  class="fa fa-sign-out"></span>
                  <span>Cerrar sesión</span>
                </el-option>
              </el-select>


             <!-- <drop-down position="right">
                <template slot="title">
                  <label class="lbl-navbar">{{currentUser.email}}</label>
                </template>
                <i slot="title" class="fa fa-user"></i>
                <a class="dropdown-item" @click="editProfile">
                  <i class="fa fa-cog"></i> Editar cuenta
                </a>
                <a class="dropdown-item text-danger" @click="logout">
                  <i class="fa fa-sign-out"></i> Cerrar sesión
                </a>
              </drop-down>-->
            </div>

          </div>
      </div>
    </div>
  </nav>
</template>
<script>
    import Helpers from "src/js/helpers/Helpers";
    import EventBus from "src/js/helpers/EventBus";
    import Settings from "src/js/helpers/Settings";
    import {Select, Option} from 'element-ui'
    let settings = new Settings();
    let helpers = new Helpers();
    import { mapGetters } from "vuex";
    import swal from 'sweetalert2'


    export default {
        components: {
            [Select.name]: Select,
            [Option.name]: Option
        },
        computed: {
            ...mapGetters(["roleUser", "isLoggedIn", "currentUser", "selected_property","properties", "changesHistory"]),
            routeName() {
                const { name } = this.$route
                return this.capitalizeFirstLetter(name)
            },
        },
        data() {
            return {
                active: null,
                active_user: 'name_user',
                activeNotifications: false,
            }
        },
        created() {
            this.chargeItems()
        },
        mounted() {
        },
        methods: {
            chargeItems(){
                this.active = this.selected_property._id;
                this.active_user = this.currentUser.email;
            },

            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            },
            logout() {
                // this.$store.dispatch('logoutUser');
                this.$store
                    .dispatch('LOGOUT')
                    .then(() => {
                        this.$router.push("/");
                    })
            },
            editProfile() {
                let user_id = this.currentUser._id;
                this.$router.push({
                    name: 'editUser',
                    params: {
                        id: user_id
                    }
                })
            },

            // reloadProperties(){
            //     EventBus.$on('RELOAD_PROPERTIES', () => {
            //         this.hotels =  helpers.getAll();
            //     })
            // },
            // changeProperty() {
            //   EventBus.$on('CLICK_PROPERTY', (property_id) => {
            //     this.hotel_is = helpers.updateSelected(property_id);
            //     EventBus.$emit('CHANGE_PROPERTY', property_id);
            //     //console.log(property_id, 'cambio de propiedad');
            //   })
            // },

            menuUser(value){
                if(value==='logout'){
                    this.logout()
                }
                if(value==='edit'){
                    this.editProfile();
                }

               //console.log('value', value);
            },
            selectProperty(value) {
               let properties = this.properties;

                let itemFinded = properties.find(item => item._id === value)
                //console.log('property_index', properties, value, idx_key);

                this.$store.dispatch('SELECT_PROPERTY', itemFinded);
                EventBus.$emit('CHANGE_PROPERTY', value);
            },
            handleProperty(value) {

                if(this.changesHistory){
                    swal({
                        title: this.$t('form.question_delete'),
                        text: 'Los cambios no guardados se perderan',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        cancelButtonClass: 'btn btn-danger btn-fill',
                        confirmButtonText: 'Si, deseo descartarlos',
                        cancelButtonText: this.$t('form.cancel'),
                        buttonsStyling: false
                    }).then((result)=> {
                        if (result.value) {
                            //console.log('Aceptar');
                            let properties = this.properties;
                            //console.log('changesHistory', this.changesHistory);
                            let itemFinded = properties.find(item => item._id === value)
                            this.$store.dispatch('SELECT_PROPERTY', itemFinded);
                            EventBus.$emit('CHANGE_PROPERTY', value);
                          this.$store.dispatch('CHANGES_ACTIVES', false);
                        }else{
                            console.log('Cancelar', this.selected_property._id);
                            this.active = this.selected_property._id;
                        }
                    });
                }else{
                    let properties = this.properties;
                    //console.log('changesHistory', this.changesHistory);
                    let itemFinded = properties.find(item => item._id === value)
                    this.$store.dispatch('SELECT_PROPERTY', itemFinded);
                    EventBus.$emit('CHANGE_PROPERTY', value);
                }
            },
        }
    }

</script>
<style lang="scss">
  .one-three {
    width: 33%;
  }
  .img-logo {
    position: relative;
    display: inline-block;
    height: 50px;
    padding: 5px 0;
    img {
      height: 100%;
    }
  }
  .contain-nav {
    white-space: nowrap;
    margin: 0;
    margin-right: 10px;
    color: #000;;
    &.mt-space {
      margin-top: 28px;
    }
  }
  .main-panel .navbar {
    max-height: 50px;
    background-color: #fff;
    position: sticky;
    z-index: 10;
    top: 0;

    .property_container {
      .selected {
        background-color: #242424;
        color: #ffffff;
        cursor: default;
      }
    }


    .btn-round.btn-icon {
      color: #ffffff;
      border-color: #ffffff;
    }
    .contain-nav {
      padding-top: 10px;
      .el-select{
        min-width: auto;
        width: auto;
        top: 0;
        margin-right: 10px;
        .el-input__inner{
          border: 0;
          white-space: nowrap;
          margin: 0;
          color: #000;
          padding-left: 5px;
          background: transparent;
          min-width: 220px;
        }
        .el-input__suffix{
          right: 0;
        }
      }
      .nav-item {
        .dropdown-menu {
          border-radius: 0;
          border: 2px solid #fff;
          background: #fff;
          a.dropdown-item {
            border-radius: 0 !important;
          }
          &:after,
          &:before {
            top: -13px;
          }
          &:before {
            border-bottom: 11px solid rgba(0, 0, 0, 0.2);
            border-left: 11px solid rgba(0, 0, 0, 0);
            border-right: 11px solid rgba(0, 0, 0, 0);
            content: "";
            display: inline-block;
            position: absolute;
          }
          &:after {
            border-bottom: 11px solid #ffffff;
            border-left: 11px solid rgba(0, 0, 0, 0);
            border-right: 11px solid rgba(0, 0, 0, 0);
            content: "";
            display: inline-block;
            position: absolute;
          }
        }
        .nav-link {
          color: #000;
        }
      }
    }
  }

  .el-select-dropdown__item.selected{
    color: #6B3E91;

  }
  .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background-color: #E1D8E9;
  }
  .el-select .el-input .el-select__caret, .el-select .el-input:hover .el-input__icon, .el-select .el-input:hover input{
    color: #000;

  }
</style>
